.support_container
  display: flex
  flex-direction: column
  margin-top: 50px
  max-width: 100%
  overflow-x: hidden
  .support_header_contaienr
    display: flex
    flex: 1
    justify-content: center
    .support_header
      display: flex
      align-items: center
      margin-bottom: 66px
      span
        position: relative
        font-weight: 700
        font-size: 30px
        line-height: 41px
        color: #2386C9
        margin: 0 16px
        &::after
          content: ""
          position: absolute
          top: calc( 100% + 13px )
          left: -20%
          width: 462px
          max-width: 100vw
          height: 2.71542px
          border-bottom: 2.71542px dashed #000000
  // .table_parent
  //   overflow-x: auto
  .table_container
    overflow-x: auto
    .table_header
      background: #2386C9
      padding: 20px 15px
      display: flex
      justify-content: space-between
      min-width: 695px
      > div
        display: flex
        justify-content: flex-start
        width: 100%
      span
        font-weight: 900
        font-size: 14.2px
        line-height: 18px
        color: #FFFFFF
    .body_container
      min-width: 695px
      background: rgba(255, 255, 255, 0.002)
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15)
      border-radius: 5px 5px 0px 0px
      max-height: 300px
      overflow-y: auto
      .table_row
        padding: 20px 15px
        display: flex
        justify-content: space-between
        > div
          display: flex
          justify-content: flex-start
          width: 100%
        &.even
          background: #F3F3F3
          span
            font-weight: 700
            font-size: 15.2px
            line-height: 18px
            color: #4B6A86
        &.odd
          background: #fff
          span
            font-weight: 500
            font-size: 15.2px
            line-height: 18px
            color: #7B7B8C
    .pagination
      margin-top: 10px
@media screen and ( max-width: 508px )
  .support_header
      span
        font-size: 22px !important
        &::after
          left: -50% !important
          max-width: 100vw !important
@media screen and ( min-width: 1200px )
  .table_container
    width: 80%
@media screen and ( max-width: 1200px )
  .table_container
    width: 85%
@media screen and ( max-width: 992px )
  .table_container
    width: 90%
@media screen and ( max-width: 768px )
  .table_container
    width: 95%
@media screen and ( max-width: 576px )
  .table_container
    width: 100%