.swiper-wrapper {
  max-width: 98vw;
}
/* .swiper-wrapper .swiper-slide {
} */
.swiper-wrapper .swiper-slide .swiper-slide-active {
  background-color: aqua;
}
.swiper-button-prev,
.swiper-button-next {
  width: 69px !important;
  height: 69px !important;
  top: 40% !important;
  border-radius: 50%;
  background: #fff;
  color: #000 !important;
  font-size: 30px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.swiper-button-next::before {
  content: "";
  position: absolute;
  top: -20px;
  right: -10px;
  width: 246px;
  height: 105px;
  background: linear-gradient(
    -90deg,
    #ffffff 27.64%,
    rgba(255, 255, 255, 0) 95.12%
  );
  z-index: -1;
}
.swiper-button-prev::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -10px;
  width: 246px;
  height: 105px;
  background: linear-gradient(
    90deg,
    #ffffff 27.64%,
    rgba(255, 255, 255, 0) 95.12%
  );
  z-index: -1;
}
.swiper-button-next::after {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-button-prev::after {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .swiper-wrapper .swiper-button-next {
}
.swiper-button-disabled {
} */
