.symbols_container
  display: flex
  flex-direction: column
  margin-top: 50px
  max-width: 100%
  overflow-x: hidden
  .symbols_header_contaienr
    display: flex
    flex: 1
    justify-content: center
    .symbols_header
      display: flex
      align-items: center
      margin-bottom: 30px
      span
        position: relative
        font-weight: 700
        font-size: 30px
        line-height: 41px
        color: #2386C9
        margin: 0 16px
        &::after
          content: ""
          position: absolute
          top: calc( 100% + 13px )
          left: -50%
          width: 269px
          height: 2.71542px
          border-bottom: 2.71542px dashed #000000
  .symbol_card
    padding: 33px 30px
    background: #181C1F
    border-radius: 2.76px
    font-weight: 500
    font-size: 20px
    line-height: 30px
    letter-spacing: -0.08px
    color: #FFFFFF
    display: flex
    justify-content: center
    align-items: center
    .img_contaienr
      width: 38px
      height: 38px
      border-radius: 50%
      overflow: hidden
      img
        width: 100%
        height: 100%
        object-fit: cover
    .symbol_text
      margin: 0px 30px