html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
iframe {
  display: none;
}
