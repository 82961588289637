.home_nav
  position: fixed
  top: 0
  left: 0
  z-index: 99
  .web_view
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100px
    background: #fff
    padding: 21px 37px 16px 72px
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25)
    .links
      a
        font-weight: 500
        font-size: 20px
        line-height: 27px
        color: #686868
        text-decoration: none
        margin-left: 20px
        cursor: pointer
        transition: 0.3s all linear
        &.active
          color: #2386C9
        &:hover
          color: #2386C9
        span
          font-weight: 500
          font-size: 20px
  .menu_icon
    position: relative
    width: 30px
    height: 25px
    cursor: pointer
    transition: 0.3s
    z-index: 9
    &:hover
      span
        background: rgba(0, 0, 0, 1)
        &::before
          background: rgba(0, 0, 0, 1)
        &::after
          background: rgba(0, 0, 0, 1)
    span
      width: 30px
      height: 3px
      background: rgba(0, 0, 0, 0.5)
      position: absolute
      top: 50%
      left: 0
      transition: 0.3s
      &::before
        transition: 0.3s
        width: 30px
        height: 3px
        background: rgba(0, 0, 0, 0.5)
        transition: 0.3s
        position: absolute
        top: -5px
        left: 0
        content: ""
      &::after
        width: 30px
        height: 3px
        background: rgba(0, 0, 0, 0.5)
        transition: 0.3s
        position: absolute
        bottom: -5px
        left: 0
        content: ""
        transition: 0.3s
      &.active
        transform: translate(0px, 5px) rotateZ(-45deg)
        &::before
          opacity: 0
        &::after
          transform: translate(0px, -5px) rotateZ(90deg)
  .mobile_view
    transition: 0.3s
    position: fixed
    width: 100vw
    height: 100vh
    top: 0
    left: 0
    .under_lay
      width: 100%
      height: 100%
      position: absolute
      background: rgba(0, 0, 0, 0.5)
      z-index: 2
    .view_container
      position: relative
      top: 0
      left: 70%
      background: #fff
      width: 30%
      height: 100%
      z-index: 3
      display: flex
      flex-direction: column
      padding: 21px 20px 30px 20px
      .logo_container
        margin-top: 60px
        width: 100%
        img
          width: 100%
          object-fit: contain
      .links
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-evenly
        flex:1
        a
          font-weight: 500
          font-size: 20px
          line-height: 27px
          color: #686868
          text-decoration: none
          cursor: pointer
          transition: 0.3s all linear
          &.active
            color: #2386C9
          &:hover
            color: #2386C9
          span
            font-weight: 500
            font-size: 20px
@media screen and ( max-width: 825px )
  .view_container
    width: 50% !important
    left: 50% !important
  .home_nav
    .web_view
      padding: 21px 37px 16px 30px
@media screen and ( max-width: 425px )
  .view_container
    width: 90% !important
    left: 10% !important
    height: 105vh !important
  .under_lay
    height: 105vh !important
  .home_nav
    .web_view
      padding: 21px 10px 16px 10px