.download_container
  margin-top: 50px
  padding: 0 10px
  .image_container
    position: relative
    margin-top: 50px
    .circle
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: 496px
      max-width: 100%
      object-fit: contain
      z-index: 1
    .phone
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: 290px
      max-width: 100%
      object-fit: contain
      z-index: 2
  .info_container
    display: flex
    flex-direction: column
    align-items: center
    .text_section
      display: flex
      flex-direction: column
      align-items: center
      border-bottom: 3px solid #2386C9
      width: 100%
      position: relative
      margin-bottom: 31px
      &::before
        content: ""
        position: absolute
        bottom: -5px
        left: -5px
        background: #2386C9
        width: 10px
        height: 10px
        border-radius: 50%
      &:after
        content: ""
        position: absolute
        bottom: -5px
        right: -5px
        background: #2386C9
        width: 10px
        height: 10px
        border-radius: 50%
      .hero_title
        font-weight: 600
        font-size: 20px
        line-height: 27px
        letter-spacing: 0.435em
        color: #404B69
      .title
        font-weight: 600
        font-size: 60px
        line-height: 82px
        text-align: center
        color: #2386C9
        margin-top: 5px
        margin-bottom: 0
      .subtitle
        font-weight: 500
        font-size: 32px
        line-height: 82px
        color: #2386C9
        margin-top: 0px
  .btns_section
    >div
      .qrcode
        margin-bottom: 30px
        mix-blend-mode: normal
        filter: drop-shadow(0px 2.61592px 2.61592px rgba(0, 0, 0, 0.25))
        padding: 10px
        width: 191px
        max-width: 100%
        object-fit: contain
    .img_fluid
      max-width: 100%
      object-fit: contain
      margin-top: 20px
@media screen and ( max-width: 900px )
  .download_container
    flex-direction: column-reverse !important
    .image_container
      position: relative
      margin-top: 400px
@media screen and ( min-width: 900px )
  .download_container
    padding: 0px 37px 0px 72px