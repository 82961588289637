.header_container
  margin-top: 100px
  position: relative
  padding: 38px 5px 30px
  .background_img
    position: absolute
    z-index: 1
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover
  .text_container
    position: relative
    z-index: 2
    display: flex
    flex-direction: column
    align-items: center
    p
      font-weight: 400
      font-size: 25px
      line-height: 34px
      text-align: center
      color: #FFFFFF
      max-width: 960px
    a
      font-weight: 600
      font-size: 25px
      line-height: 16px
      text-align: center
      color: #FFFFFF
      padding: 22px 30px
      border: 2px solid #FFFFFF
      border-radius: 20px
      margin-top: 25px
      text-decoration: none
      cursor: pointer