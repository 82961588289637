.features_container
  padding: 0px 37px 0px 72px
  margin-top: 50px
  .subtitle
    font-weight: 600
    font-size: 20px
    line-height: 27px
    letter-spacing: 0.435em
    color: #404B69
    margin-bottom: 10px
  .title
    font-weight: 500
    font-size: 30px
    line-height: 41px
    color: #2386C9
    position: relative
    margin-bottom: 59px
    &::after
      content: ""
      width: 186px
      height: 3px
      background-color: #404B69
      position: absolute
      top: calc( 100% + 9px )
      left: 0
  .cards_container
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    .card_container
      display: flex
      flex-direction: column
      background: #FFFFFF
      border: 0.541512px solid #DBE4E9
      border-radius: 10.8302px
      min-width: 254px
      padding: 10px 14px 20px
      margin-top: 20px
      .card_index
        font-weight: 800
        font-size: 11.9133px
        line-height: 16px
        color: #000000
      .card_image_container
        display: flex
        justify-content: center
      .card_title
        font-weight: 800
        font-size: 16.2454px
        line-height: 18px
        text-align: center
        letter-spacing: -0.03em
        color: #000000
        margin-top: 15px
.expert_advisor
  align-items: center
  .card_index
    width: 100%
    display: flex
    justify-content: flex-start
  .card_image_container
    margin-bottom: 12px
    display: flex
    justify-content: center
    max-width: 140px
    img
      width: 100%
      object-fit: contain
.accurate_action
  align-items: center
  .card_index
    width: 100%
    display: flex
    justify-content: flex-start
  .card_image_container
    // margin-bottom: 8px
    display: flex
    justify-content: center
    width: 120px
    background: linear-gradient(246.49deg, #F2F2F2 -95.75%, #E6F5FF 131.17%)
    height: 120px
    border-radius: 50%
    align-items: center
    img
      height: 80%
      object-fit: contain
@media screen and ( min-width: 1200px )
  .cards_container
    justify-content: center !important
    .card_container
      margin-right: 20px
@media screen and ( max-width: 1140px )
  .cards_container
    justify-content: flex-start !important
    .card_container
      margin-right: 20px
@media screen and ( max-width: 440px )
  .cards_container
    justify-content: flex-start !important
    .card_container
      margin-right: 0px
      margin-top: 20px