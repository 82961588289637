.status_container
  display: flex
  width: 100% !important
  flex: 1
  margin-top: 50px
  .status_card_container
    transition: all .3s ease-in-out
    width: 25%
    display: flex
    flex-direction: column
    align-items: center
    padding: 33px 0
    .icon
      max-width: 100%
      object-fit: contain
      margin-bottom: 12px
    .topTitle, .bottomTitle
      font-weight: 600
      font-size: 20px
      line-height: 157.18%
      text-align: center
      color: #FFFFFF
    .number
      font-weight: 800
      font-size: 26px
      line-height: 157.18%
      text-align: center
      color: #FFFFFF
