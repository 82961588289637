.privacy_container
  background: #fff
  .privacy_header
    display: flex
    justify-content: center
    align-items: center
    padding-top: 21px
    max-width: 100%
    margin-bottom: 60px
    img
      object-fit: contain
      max-width: 100%
  .privacy_body
    padding: 0 72px !important
    .title
      font-weight: 500
      font-size: 30px
      line-height: 41px
      color: #2386C9
      position: relative
      margin-bottom: 41px
      &::after
        content: ""
        width: 186px
        height: 3px
        background-color: #404B69
        position: absolute
        top: calc( 100% + 9px )
        left: 0
    .text
      font-weight: 400
      font-size: 20px
      line-height: 200.68%
      color: #686868
    
@media screen and ( max-width: 900px )
  .privacy_container
    .privacy_body
      padding: 0 10px !important